import React from 'react'
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
const Styled = styled.section`
    max-width: 600px;
    margin: 0px auto 100px;
    text-align: center;
     .title {
    font-size: clamp(40px, 5vw, 60px);
}
.email-link {
    color: var(--green);
    background-color: transparent;
    border: 1px solid var(--green);
    border-radius: var(--border-radius);
    padding: 1.25rem 1.75rem;
    font-size: var(--fz-sm);
    font-family: var(--font-mono);
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: var(--transition);
    margin-top: 50px;
}
.overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;
}
.numbered-heading::after {
    display: none;
}
`;
const Contact = () => {
  return (
    <Styled  id='Contact'>
       <Fade bottom delay={500} ><h2 className="numbered-heading overline">What’s Next?</h2></Fade> 
       <Fade bottom cascade delay={500}>
        <div>
        <h2 className="title">Get In Touch</h2>
        <p>Although I’m not currently looking for any new opportunities, my inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you!</p>
        <a className="email-link" href="mailto:raidmarewane2000@gmail.com" rel="noopener noreferrer" target="_blank">Say Hello</a>
        </div>

        </Fade> 
    </Styled>
  )
}

export default Contact