import Header from "./widget/Header";
import styled from 'styled-components';
import { FiGithub,FiInstagram,FiLinkedin } from "react-icons/fi";
import Home from "./section/Home";
import About from "./section/About";
import WorkEx from "./section/WorkEx";
import Projects from "./section/Projects";
import Contact from "./section/Contact";
import Footer from "./widget/Footer";

const Styled = styled.div`
.LeftSection {
  position: fixed;
  bottom: 0;
  right:35px;
  color: #e6f1ffc7;
}
.RightSection{
  position: fixed;
  bottom: 0;
  left:35px;
  color: #e6f1ffc7;
}


.LeftSection a::after ,.RightSection ul::after{
  content: "";
  display: block;
  width: 1px;
  height: 90px;
  margin: 30px auto;
  background-color: var(--light-slate);
}

.LeftSection a{
  margin: 20px auto;
  padding: 10px;
  font-family: var(--font-mono);
  font-size: var(--fz-xxs);
  line-height: var(--fz-lg);
  letter-spacing: 0.1em;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  color: #b5c1d1;
}

.RightSection svg {
    width: 20px;
    height: 20px;
    margin: 10px 0;
    color: #e6f1ffc7;
}
main{
  padding: 0px 150px;
}
@media (max-width: 768px){
  main{
  padding: 0px 40px;
}
.LeftSection,.RightSection{
  display: none;

}
}


`;

function App() {
  return (
    <Styled>
    <Header/>
    <main>
    <Home/>
    <About/>
    <WorkEx/>
    <Projects/>
    <Contact/>
    {/*

     
  
    
    
    */}
    <Footer/>
    </main>
 



    <div className="LeftSection">
    <a target="_blank" href="mailto:raidmarewane2000@gmail.com" >Raidmarewane2000@gmail.com</a>
    </div>
    <div className="RightSection">
      <ul>
        <li><a  target="_blank" href="https://github.com/raidmarewane"><FiGithub/></a></li>
        <li><a target="_blank" href="https://www.instagram.com/raid_van_buuren_"><FiInstagram/></a></li>
        <li><a target="_blank" href="https://www.linkedin.com/in/djehiche-raid"><FiLinkedin/></a></li>
      </ul>
    </div>
    </Styled>
  );
}

export default App;
