import React from 'react';
import { Fade } from 'react-reveal';
import styled from 'styled-components';
const Styled = styled.div`
    font-family: var(--font-mono);
    font-size: var(--fz-xxs);
    line-height: 1;
    text-align: center;
    
    padding: 0px 0 15px 0px;

`;

const Footer = () => {
    return (
        <Styled><Fade bottom delay={500} >Designed &amp; Built by Raid Djhaiche </Fade></Styled>
    )
}

export default Footer