import React from 'react';
import styled from 'styled-components';
import raid from '../imag/me.png';
import Fade from 'react-reveal/Fade';
const Styled = styled.section`
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
    margin: 0px auto;
    padding: 100px 0px;
    max-width: 1000px;
    .inner{
        display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 50px;
    }
    .About_2{
    position: relative;
    }
    img{
        max-width: 400px;
    }
    p{
        font-size: 20px;
    }
    ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    gap: 0px 10px;
    padding: 0px;
    margin: 20px 0px 0px;
    overflow: hidden;
    list-style: none;
}
ul.skills-list li::before {
    content: "▹";
    position: relative;
    left: 0px;
    color: var(--green);
    font-size: var(--fz-sm);
    line-height: 12px;
    padding: 0 10px 0 0;
}
ul.skills-list li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
}
@media (max-width: 768px){
.inner{
    grid-template-columns: auto;
    max-width: 100%;
    display: block;
}
img {
    max-width: 300px;
}
padding: 0px 0px;
}

`;
const About = () => {
    return (
            <Styled id='about' className="HeroSection">
                <Fade bottom  delay={500}>
                    <h2 className="numbered-heading">About Me</h2>
                </Fade>
               <div className="inner">
                <div className='About_1'>
                <Fade bottom delay={1000}><p>Hello ! I'm Raid Djehaiche, I have a master's degree at the University of M'Sila, majoring in media. I developed several websites and created mobile applications. I worked as a developer in freelancing for a year, then I worked in a development company for two years.</p>
                <p>Here are a few technologies I’ve been working with recently:</p></Fade>
                <Fade bottom delay={1000}>
                    <ul className="skills-list"><li>Php</li><li>TypeScript</li><li>React</li><li>Socket io</li><li>Node.js</li><li>WordPress</li></ul>
                </Fade>
                </div>
                <div className='About_2'>
                    <div className='wrappers'>
                    <Fade right  delay={1500}><img alt={"raid djhaiche"} src={raid}/></Fade>
                    </div>
                  
                </div>
                </div>
            </Styled>
  
    )
}

export default About