import React from 'react';
import styled from 'styled-components';
import Slide from 'react-reveal/Slide';
const Styled = styled.section`
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
    margin: 0px auto;
    padding: 100px 0px;
    max-width: 1000px;
    h1 {
    margin: 0px 0px 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm),5vw,var(--fz-md));
    font-weight: 400;
}
h3 {
    margin-top: 10px;
    color: var(--slate);
    line-height: 0.9;
}
 p {
    margin: 20px 0px 0px;
    max-width: 540px;
}
.email-link {
    color: var(--green);
    background-color: transparent;
    border: 1px solid var(--green);
    border-radius: var(--border-radius);
    padding: 1.25rem 1.75rem;
    font-size: var(--fz-sm);
    font-family: var(--font-mono);
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: var(--transition);
    margin-top: 50px;

}
.email-link:hover, .email-link:focus,  .email-link:active {
    background-color: var(--green-tint);
    outline: none;
}
p{
    font-size: 20px;
}
`;

const Home = () => {
    return (
            <Styled className="HeroSection">
                <Slide bottom cascade>
                <div>
                    <h1>Hi, my name is</h1>
                <h2 className="big-heading">Raid Djhaiche.</h2>
                <h3 className="big-heading">I build things for the web.</h3>
                <p>I’m a software engineer specializing in building (and occasionally designing) exceptional digital experiences.</p>
                <a className="email-link" href='/RaidDjhaiche.pdf' target='_blank'  rel="noreferrer">Check out my Resume!</a>
                </div>
                </Slide>
            </Styled>
  
    )
}

export default Home