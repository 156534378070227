import React from 'react';
import styled from 'styled-components';
import SectionProject from '../widget/SectionProject';
import imag1 from '../imag/01.webp'
import imag2 from '../imag/02.webp'
import { Fade } from 'react-reveal';
const Styled = styled.section`
margin: 0px auto;
padding: 100px 0px;
max-width: 1000px;
@media (max-width: 768px){

}
`;
const Projects = () => {
  return (
    <Styled id='projects'>
        <Fade bottom><h2 className="numbered-heading">Some Things I’ve Built</h2></Fade>
        <ul>
          <li>
          <Fade bottom delay={500}><SectionProject 
          imag={imag1}
          titel={"Taxi mobile app"} 
          description={"A app for Taxi with( real time chat ) and many options like notification and sms , this application is optimized to execute many booking in same time without any issue (1000 booking in same time)"}
          list={["Fastify","Socket.io","Node.js","Redis","Flutter","MongoDB"]}
        /></Fade>
          </li>
          <li>
          <Fade bottom delay={500}><SectionProject 
            imag={imag2}
          titel={"Delivery App"} 
          description={"Delivery app and is complete on-demand delivery management . A customer can request services hassle-free through mobile and web apps. Allow your customers to track their service in real-time and make online payments through multiple payment gateways."}
          list={["Fastify","Socket.io","Node.js","Flutter","MongoDB"]}
        /></Fade>
          </li>
        </ul>
        
      
    </Styled>
  )
}

export default Projects