
import React from 'react';
import styled from 'styled-components';
const Styled = styled.div`
margin-bottom: 100px;
position: relative;
display: grid;
gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    -webkit-box-align: center;
    align-items: center;
    :nth-of-type(2n+1) .project-content {
    grid-column: 7 / -1;
    text-align: right;
}
.project-content {
    position: relative;
    grid-area: 1 / 1 / -1 / 7;
}
:nth-of-type(2n+1) .project-image {
    grid-column: 1 / 8;
}
.project-image {
    box-shadow: 0 10px 30px -15px rgba(2,12,27,0.7);
    transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
    grid-area: 1 / 6 / -1 / -1;
    position: relative;
    z-index: 1;
}

.project-image a {
    width: 100%;
    height: 100%;
    background-color: #64ffda;
    border-radius:  4px;
    vertical-align: middle;
}
.project-image .img {
    border-radius:  4px;
    mix-blend-mode: multiply;
    filter: grayscale(100%) contrast(1) brightness(90%);
}
.gatsby-image-wrapper img {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-width: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
}
.project-image a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0px;
    z-index: 3;
    transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
    background-color: #0a192f;
    mix-blend-mode: screen;
}
.project-overline {
    margin: 10px 0px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    font-weight: 400;
}
.project-description:hover,  .project-description:focus {
    box-shadow: 0 20px 30px -15px var(--navy-shadow);
}
.project-description {
    box-shadow: 0 10px 30px -15px var(--navy-shadow);
    transition: var(--transition);
    position: relative;
    z-index: 2;
    padding: 25px;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    color: var(--light-slate);
    font-size: var(--fz-lg);
}
:nth-of-type(2n+1) .project-tech-list {
    -webkit-box-pack: end;
    justify-content: flex-end;
}
 .project-tech-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 25px 0px 10px;
    padding: 0px;
    list-style: none;
}
 .project-tech-list li {
    margin: 0px 20px 5px 0px;
    color: var(--light-slate);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    white-space: nowrap;
}
:nth-of-type(2n+1) .project-tech-list li {
    margin: 0px 0px 5px 20px;
}
.project-image a:hover, .project-image a:focus {
    background: transparent;
    outline: 0px;
}
.project-image a:hover::before, .project-image a:focus::before, .project-image a:hover .img, .project-image a:focus .img {
    background: transparent;
    filter: none;
}
@media (max-width: 480px){
:nth-of-type(2n+1) .project-content {
    padding: 25px 25px 20px;
}
}
@media (max-width: 768px){
    .project-image {
    display: none;
}
:nth-of-type(2n+1) .project-content {
    grid-column: 1 / -1;
    padding: 0px 0px 30px;
    text-align: left;
}
.project-title {
    color: var(--white);
}
 .project-description {
    padding: 20px 0px;
    background-color: transparent;
    box-shadow: none;
}
:nth-of-type(2n+1) .project-tech-list {
    -webkit-box-pack: start;
    justify-content: flex-start;
}
 .project-content {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    grid-column: 1 / -1;
    padding: 40px 40px 30px;
    z-index: 5;
}
}

`;
const SectionProject = (props) => {
    return (
        <Styled>
            <div className="project-content">
                    <p className="project-overline">Featured Project</p>
                    <h3 className="project-title">
                        <a>{props.titel}</a>
                        </h3>
                    <div className="project-description">
                        <p>{props.description} </p>
                    </div>
                    <ul className="project-tech-list">
                        {
                            props.list.map((data,i)=> <li key={i}>{data}</li>)
                        }

                    </ul>
            </div>
            <div className="project-image">
                <a>
                <div className='project-image img'>
                <img  style={{
                       maxWidth:"100%",
                       borderRadius:"5px"
                }}
                src={props.imag}
                />
                </div>
                </a>
            </div>
        </Styled>
    )
}

export default SectionProject