import React, { useState } from 'react'
import { Navbar } from 'react-bootstrap'
import styled from 'styled-components';
import im from '../imag/logo.png';
import { useMediaQuery } from 'react-responsive'
import Fade from 'react-reveal/Fade';
const Styled = styled.div`
    background-color: rgba(10, 25, 47, 0.85);
    backdrop-filter: blur(10px);
    font-family: var(--font-mono) !important;
    ol{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    list-style: none;
    }
    ol>div {
    
        display: flex;

}
    ol li{
     margin: 0px 5px;
    position: relative;
    counter-increment: item 1;
    font-size: var(--fz-xs);
    }
    ol li a::after {
    content: " ()";
    margin-right: 5px;
    color: #64ffda;
    text-align: right;
    font-size: 13px;
    }
    li a {
    color: #e6f1ffc7 ;
    padding: 10px;
    font-size: 13px;
    }
    .resume-button {
    color: var(--green);
    background-color: transparent;
    border: 1px solid var(--green);
    border-radius: var(--border-radius);
    padding: 0.75rem 1rem;
    font-family: var(--font-mono);
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: var(--transition);
    margin-left: 15px;
    font-size: var(--fz-xs);
}
span.navbar-brand img {
    max-width: 40px;
}
nav.navbar.navbar-expand.navbar-light.fixed-true {
    padding: 30px 60px;
}
.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
    padding: 10px 40px;
}
position: fixed;
width: 100%;
z-index: 9999999999;
button{
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    z-index: 10;
    margin-right: -15px;
    padding: 15px;
    border: 0px;
    background-color: transparent;
    color: inherit;
    text-transform: none;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
}
.ham-box {
    display: inline-block;
    position: relative;
    width: var(--hamburger-width);
    height: 24px;
}
.ham-box-inner {
    position: absolute;
    top: 50%;
    right: 0px;
    width: var(--hamburger-width);
    height: 2px;
    border-radius: var(--border-radius);
    background-color: var(--green);
    transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
    transform: rotate(0deg);
}
.ham-box-inner::before {
    width: 120%;
    top: -10px;
    opacity: 1;
    transition: var(--ham-before);
}
 .ham-box-inner::before, .ham-box-inner::after {
    content: "";
    display: block;
    position: absolute;
    left: auto;
    right: 0px;
    width: var(--hamburger-width);
    height: 2px;
    border-radius: 4px;
    background-color: var(--green);
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
}
.ham-box-inner::after {
    width: 80%;
    bottom: -10px;
    transform: rotate(0deg);
    transition: var(--ham-after);
}
.asider{
    z-index: 99999999;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    padding: 50px 10px;
    width: min(75vw, 250px);
    height: 100vh;
    outline: 0px;
    background-color: var(--light-navy);
    box-shadow: -10px 0px 30px -15px var(--navy-shadow);
    z-index: 9;
    transform: translateX(0vw);
    visibility: visible;
    transition: var(--transition);
}

    aside.asider.open {
    right: -75px;
    width: 0;
}

.asider nav {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    color: var(--lightest-slate);
    font-family: var(--font-mono);
    text-align: center;
}
.asider ol {
    padding: 0px;
    margin: 0px;
    list-style: none;
    width: 100%;
    display: block;
    text-align: left;
}
`;

const Header = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    const [open,Setopen] = useState(0);
    return (
        <Styled>
        <Navbar sticky="top">
                <Navbar.Brand>
                    <img src={im} />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    {!isTabletOrMobile?<><ol>
                        <Fade bottom cascade>
                        <div>
                        <li><a href="/#about">About</a></li>
                        <li><a href="/#jobs">Experience</a></li>
                        <li><a href="/#projects">Work</a></li>
                        <li><a href="/#Contact">Contact</a></li>
                        </div>
                        </Fade>
                    </ol>
                    <Fade bottom ><a className="resume-button"  href='/RaidDjhaiche.pdf'  target="_blank" rel="noopener noreferrer">Resume</a>  </Fade></>:<> 
                        {/*<button onClick={(e)=>Setopen(!open)}>
                           <div class="ham-box">
                            <div class="ham-box-inner">
                            </div>
                            </div>
                        </button>
                        <aside aria-hidden="false" tabindex="1" class={open?"asider":"asider open"}><nav>
                            <ol>
                                <li><a href="/#about">About</a></li><li><a href="/#jobs">Experience</a></li>
                                <li><a href="/#projects">Work</a></li><li><a href="/#contact">Contact</a></li>
                                <li> <a className="resume-button"  href='/RaidDjhaiche.pdf'  target="_blank" rel="noopener noreferrer">Resume</a></li>
                    </ol></nav></aside>*/}
                        </>}
                </Navbar.Collapse>
        </Navbar>

        </Styled>
    )
}

export default Header