import React from 'react';
import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useMediaQuery } from 'react-responsive'
import { Fade } from 'react-reveal';
const Styled = styled.section`
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
    margin: 0px auto;
    padding: 100px 0px;
    max-width: 700px;
    .css-rz77d6-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #64ffda !important;
}
button{
    color:var(--slate) !important;
    text-transform: capitalize;
}
.css-35zqo5-MuiTabs-root{
    border-right: 1.5px solid;
    border-color: #233554;
    min-width: 100px;

}
    //var(--light-navy)
     h3 .company {
    color: var(--green);
} 
h3 {
    margin-bottom: 2px;
    font-size: var(--fz-xxl);
    font-weight: 500;
    line-height: 1.3;
}
.range {
    margin-bottom: 25px;
    color: var(--light-slate);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
}
ul li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
}
ul li::before {
    content: "▹";
    position: absolute;
    left: 0px;
    color: var(--green);
}
@media (max-width: 768px){
    padding: 0px 0px;
}
`;
function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const WorkEx = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Styled  id='jobs' className="HeroSection">
             <Fade bottom><h2 className="numbered-heading">Where I’ve Worked</h2></Fade>
            <Box
                sx={{  width: "100%", display: !isTabletOrMobile?'flex':'block', height: "250px" }}
            >
                <Fade bottom  delay={500}><Tabs
                    orientation={!isTabletOrMobile?"vertical":"horizontal"}
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    <Tab label="DevGate" {...a11yProps(0)} />
                    <Tab label="Codex" {...a11yProps(1)} />
                    <Tab label="Freelance " {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <h3><span>Engineer backend </span><span className="company"><a> @DevGate</a></span></h3>
                    <p className="range">January - 2022</p>
                    <div><ul>
                        <li>Communicate with multi-disciplinary teams of engineers, designers, producers, and clients on a daily basis</li>
                        <li>Work with a variety of different languages, platforms, frameworks, and content management systems such as JavaScript, TypeScript, React, WordPress</li>
                        <li>Build a taxi app and optimization google map resource use intelligent artificial </li>
                    </ul></div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <h3><span>Engineer backend </span><span className="company"><a> @Codex</a></span></h3>
                    <p className="range">July - 2020</p>
                    <div><ul>
                        <li>Developed highly interactive web applications(System livraison express with Parcel tracking)</li>
                        <li>Build Travel agency management </li>
                        <li>Custom PC Builder in WooCommerce (WordPress) </li>
                        <li>Build a food delivery app  </li>
                    </ul></div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                <h3><span>Freelancer </span><span className="company"><a></a></span></h3>
                    <p className="range">January - 2019</p>
                    <div><ul>
                        <li>Worked on projects with teams and individuals on a contract basis</li>
                        <li>Build Many website ecommerce </li>
                    </ul></div>
                </TabPanel>
</Fade>
            </Box>
        </Styled>

    )
}

export default WorkEx